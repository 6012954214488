.nav-link a{
    text-decoration: none!important;
}
.nav-link:focus, .nav-link:hover, .nav-link:active {
    text-decoration: none;
    color: #ffffff;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
    border-color: #ffffff;
    border-radius: 30px 30px;
}
:target {
    background-color: rgb(212, 46, 16);
}
.nav-link{
    text-align: center;
}
.navbar-dark .navbar-brand {
    color: #051b30!important;
}
